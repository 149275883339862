'use client';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '@carbonfact/ui-components/src/Loader';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function Auth0LoginPage() {
  const { loginWithRedirect } = useAuth0();

  const currentSearchParams = useSearchParams();

  useEffect(() => {
    void loginWithRedirect({
      openUrl: (url) => {
        const newUrl = new URL(url);

        // Pass URL params like org invitation and password reset tokens to Auth0
        for (const [key, value] of currentSearchParams.entries()) {
          newUrl.searchParams.append(key, value);
        }

        // Force actual browser navigation to trigger a next.js refetch as we are not
        // a "proper" client-side SPA, so just chaging window.location won't load up
        // pages properly
        window.open(newUrl.toString(), '_self');
      },
    });
  }, [loginWithRedirect, currentSearchParams]);

  return <Loader type="fullpage" />;
}
